import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { setCapitalLetter } from 'utils/validations';
import { setDataToLink } from 'utils';
import {
  TransfersIcon,
  PublicationIcon,
  AwardIcon,
  MoneyBag,
  InventoryIcon,
  ReportsIcon,
  EventLog,
  Admin
} from 'utils/icons';
import { iconsMenu } from 'utils/menuIcons';
import { requestMenu } from 'redux/actions/userActions';
import { Layout, Menu, Icon } from 'antd';
import PropTypes from 'prop-types';
import { chunkTitle } from 'helpers/helperForms';
import { inventoryResetData, inventoryResetQuery } from 'modules/Inventory/redux-saga/actions';
import { cleanIsFromAdverts } from 'pages/CasesDetail/redux/actions/index';
import { cleanIncomingDetailInvt } from 'redux/actions/caseActions';
import { useTranslation } from 'react-i18next';
function SideNavBar(props) {
  const {
    collapsed,
    onCollapse,
    externalRegister,
    openKeys,
    onChange,
    menu,
    inventoryResetData: resetInventoryData,
    inventoryResetQuery: resetInventoryQuery,
    cleanIsFromAdverts: setCleanFromAdverts,
    cleanIncomingDetailInvt: cleanCaseInventoryInfo
  } = props;

  const { Sider } = Layout;
  const { SubMenu } = Menu;
  let notIncludes = [213, 14, 15];
  const propsSider = {
    collapsible: true,
    collapsed: collapsed,
    onCollapse: onCollapse
  };
  const propsSideNav = !externalRegister ? propsSider : { collapsed: true };
  const dispatch = useDispatch();
  const customIcons = [5, 6, 7, 8, 9, 10];
  const { t } = useTranslation();

  let insured = [];
  useEffect(() => {
    const menuSideBar = () => {
      dispatch(requestMenu());
    };
    if (!externalRegister) menuSideBar();
  }, [dispatch, externalRegister]);

  const isExternal = menu && !externalRegister;

  const cleanData = useCallback(
    keyMenu => {
      switch (Number(keyMenu)) {
        case 1101:
          resetInventoryData();
          resetInventoryQuery();
          break;

        default:
          break;
      }
    },
    [resetInventoryData, resetInventoryQuery]
  );

  if (isExternal) {
    menu.map(item => {
      if (item.id === 1) {
        let lengthPages = Object.keys(item.pages);
        if (lengthPages.length > 1) {
          lengthPages.map(sub => {
            const subItem = item.pages[sub];
            if (notIncludes.includes(subItem.id)) {
              insured = [...insured, subItem];
              return false;
            }
            return null;
          });
        }
      }
      return false;
    });
  }

  const expensiveMenuGenerate = () => {
    if (isExternal) {
      return (
        <Menu
          theme="light"
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onChange}
          onClick={e => {
            cleanData(e.key);
            dispatch(setCleanFromAdverts());
            dispatch(cleanCaseInventoryInfo());
          }}
          className={openKeys.length > 0 && !collapsed ? 'ant-layout-sider-children-over' : ''}
          style={{
            overflowY: 'auto',
            overflowX: 'hidden',
            height: 'calc(100% - 60px)'
            // pointerEvents: 'none',
            // opacity: '.5'
          }}
        // className={openKeys.includes('200') ? 'ant-layout-sider-children-over' : undefined}
        >
          {menu &&
            menu.length > 0 &&
            menu.map(item => {
              if (item.pages) {
                const totalPages = Object.keys(item.pages).length;
                let nameCapital = setCapitalLetter(item.name);
                if (totalPages >= 1 && item.id !== 100 && item.id !== 800) {
                  return (
                    <SubMenu
                      key={item.id}
                      title={
                        <span {...chunkTitle(nameCapital, 13)}>
                          {item.icon === 5 && (
                            <React.Fragment>
                              <TransfersIcon />
                              <span style={{ marginLeft: '-2px' }}>{nameCapital}</span>
                            </React.Fragment>
                          )}
                          {item.icon === 6 && (
                            <React.Fragment>
                              <PublicationIcon />
                              <span style={{ marginLeft: '-2px' }}>{nameCapital}</span>
                            </React.Fragment>
                          )}
                          {item.icon === 7 && (
                            <React.Fragment>
                              <AwardIcon />
                              <span style={{ marginLeft: '-2px' }}>{nameCapital}</span>
                            </React.Fragment>
                          )}
                          {/* 
                          {item.icon === 8 && (
                            <React.Fragment>
                              <Icon style={{ marginLeft: '2px' }} type={iconsMenu[item.icon]} />
                              <span>{nameCapital}</span>
                            </React.Fragment>
                          )} */}
                          {item.icon === 8 && (
                            <React.Fragment>
                              <MoneyBag />
                              <span style={{ marginLeft: '-2px' }}>{nameCapital}</span>
                            </React.Fragment>
                          )}
                          {item.icon === 9 && (
                            <React.Fragment>
                              <InventoryIcon />
                              <span style={{ marginLeft: '-2px' }}>{nameCapital}</span>
                            </React.Fragment>
                          )}
                          {item.icon === 10 && (
                            <React.Fragment>
                              <ReportsIcon />
                              <span style={{ marginLeft: '-2px' }}>{nameCapital}</span>
                            </React.Fragment>
                          )}
                          {item.icon === 11 && (
                            <React.Fragment>
                              <EventLog />
                              <span style={{ marginBottom: '20px', marginLeft: '-25px' }} />
                            </React.Fragment>
                          )}
                          {item.icon === 12 && (
                            <React.Fragment>
                              <Admin />
                              <span style={{ marginBottom: '20px', marginLeft: '-25px' }} />
                            </React.Fragment>
                          )}
                          {item.iconImage != null && (
                            <React.Fragment>
                              <Icon type={item.iconImage} />
                              <span style={{ marginBottom: '20px', marginLeft: '-25px' }} />
                            </React.Fragment>
                          )}
                          {!customIcons.includes(item.icon) && (
                            <React.Fragment>
                              <Icon type={iconsMenu[item.icon]} />
                              <span>{nameCapital}</span>
                            </React.Fragment>
                          )}
                        </span>
                      }
                    >
                      {Object.keys(item.pages).map(sub => {
                        const subItem = item.pages[sub];
                        if (subItem.visible)
                          if (subItem.name !== '' && subItem.visible) {
                            if (!subItem.pages) {
                              return (
                                <Menu.Item key={subItem.id}>
                                  {setDataToLink(
                                    Link,
                                    subItem.url,
                                    { groupId: item.id, pageId: subItem.id },
                                    `${subItem.name}`
                                  )}
                                </Menu.Item>
                              );
                            } else if (subItem.pages && subItem.pages.length > 0) {
                              return (
                                <SubMenu
                                  key={subItem.id}
                                  title={
                                    <span
                                      style={{
                                        fontFamily: 'Montserrat',
                                        fontSize: '12px',
                                        fontWeight: '500'
                                      }}
                                      title={t('usersbuyersvalidate.header')}
                                    >
                                      {`${subItem.name}`}
                                    </span>
                                  }
                                >
                                  {subItem.pages.map(subPages => {
                                    if(subPages.pages && subPages.pages.length > 0) {
                                      return(
                                        <SubMenu
                                          key={subPages.id}
                                          title={
                                            <span
                                              style={{
                                                fontFamily: 'Montserrat',
                                                fontSize: '12px',
                                                fontWeight: '500'
                                              }}
                                              title={t('usersbuyersvalidate.header')}
                                            >
                                              {`${subPages.name}`}
                                            </span>
                                          }>
                                          {subPages.pages.map(subSubPages => {
                                            return (
                                              <Menu.Item key={subSubPages.id}>
                                                {setDataToLink(
                                                  Link,
                                                  subSubPages.url,
                                                  { groupId: subSubPages.id, pageId: subSubPages.id },
                                                  subSubPages.name,
                                                  () => { }
                                                )}
                                              </Menu.Item>
                                            );
                                          })}
                                          </SubMenu>
                                      )
                                    }else{
                                      return (
                                        <Menu.Item key={subPages.id}>
                                          {setDataToLink(
                                            Link,
                                            subPages.url,
                                            { groupId: subPages.id, pageId: subPages.id },
                                            subPages.name,
                                            () => { }
                                          )}
                                        </Menu.Item>
                                      );
                                    }
                                  })}
                                </SubMenu>
                              );
                            }

                            return null;
                          }
                        return null;
                      })}
                    </SubMenu>
                  );
                } else if (item.visible && item.id !== 800) {
                  return (
                    <Menu.Item key={item.id}>
                      <Link to="/dashboard" className="">
                        <Icon type={iconsMenu[item.icon]} />
                        <span> {item.name}</span>
                      </Link>
                    </Menu.Item>
                  );
                }
              }
            })}
        </Menu>
      );
    } else {
      return null;
    }
  };

  const createMenu = React.useMemo(expensiveMenuGenerate, [menu, openKeys]);
  return (
    <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        marginTop: '64px',
        minHeight: '84vh',
        left: 0
      }}
      {...propsSideNav}
    >
      {createMenu}
    </Sider>
  );
}

SideNavBar.propTypes = {
  openKeys: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    menu: state.user.menu && state.user.menu.menuArray
  };
}

export default connect(mapStateToProps, {
  inventoryResetData,
  inventoryResetQuery,
  cleanIsFromAdverts,
  cleanIncomingDetailInvt
})(SideNavBar);
