import React from 'react';
import notAllowed from '../../../assets/img/turnLeft.svg';
function UserNotAllowed() {
  return (
    <div style={{ position: 'absolute', width: '90%', margin: '0 auto', marginTop: '120px' }}>
      <img width="150px" src={notAllowed} laoding="lazy" alt="usuario no permitido" />
      <br />
      <h4 style={{ marginTop: '1rem' }}>No tiene permitido el acceso a está página</h4>
    </div>
  );
}

export default UserNotAllowed;
