import React, { useState } from 'react';
import { Button, Icon } from 'antd';
import { notification } from 'antd';
import { configError, configInfo, reduce_image_file_size, calc_image_size } from '../../../utils';
import PropTypes from 'prop-types';
import { getCaseIdByFileName } from '../../../services/cases';
import useIsMounted from '../../../hooks/useIsMounted';
import { useTranslation } from 'react-i18next';
import { validZipFiles } from 'utils/constants';

const UploadInput = React.forwardRef((props, ref) => {
  const {
    additionalValidations,
    allowedNames,
    allowSize,
    allowsMultipleFiles,
    customMessage,
    formatsText,
    getFileBase64,
    instructionLabel,
    instructionIcon,
    showErrorMessage,
    succesLabel,
    typeButton,
    validTypesFile,
    rootStyle,
    iconStyle,
    validImageFileExtensions,
    defaultName,
    reziseImage,
    massivePhotoFile,
    totalImages,
    isProof,
    ...rest
  } = props;

  const { t } = useTranslation();

  let size = allowSize ? allowSize : 10;

  const [nameFile, setNameFile] = useState('');
  let statusFiles = [];
  const [isValidated, setIsValidated] = useState(false);

  const fileInputKey =
    ref && ref.currrent && ref.current.value ? ref.current.value.name : +new Date();
  const initialMount = useIsMounted();
  let arrayBase64 = [];

  const getBase64 = (file, callback) => {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      async () => {
        try {
          let urlImg = reader.result;
          let sizePicture = calc_image_size(urlImg);
          let base = '';
          if (sizePicture > 6000 && reziseImage) {
            let reduce_image = await reduce_image_file_size(urlImg);
            let splitString = reduce_image.split('base64,');
            base = splitString[1];
          } else {
            let splitString = reader.result.split('base64,');
            base = splitString[1];
          }
          callback(base);
          reader = null;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error >>> ', error);
        }
      },
      false
    );
    reader.readAsDataURL(file);
    //reader.removeEventListener('load');
  };

  const handleChange = async e => {
    let imageTot = 0;
    if (!totalImages) {
      imageTot = 0;
    } else {
      imageTot = totalImages;
    }
    if (initialMount.current) setNameFile('');
    if (e.persist) {
      e.persist();
    }

    let file = false;
    let sizeFiles = e.target.files.length;
    // totalFilesUploaded = sizeFiles;
    notification.destroy();
    if (allowsMultipleFiles && e.target.files.length > 1) {
      let arrayFiles = [...e.target.files];
      const haveZip = arrayFiles.filter(f => validZipFiles.includes(f.type));
      if ((haveZip.length == 0  && e.target.files.length + imageTot <= 15) || (haveZip.length > 0 && e.target.files.length + imageTot <= 200)) {
        arrayFiles.forEach(async f => {
          if (f) {
            let validFile = await validateFile(f);
            let howManyValids = statusFiles.filter(fileFilter => fileFilter.isValid);
            //let howManyInValids = statusFiles.filter(f => !f.isValid);
            if (validFile && initialMount.current) {
              getBase64(f, result => {
                let aux = result;

                arrayBase64 = [...arrayBase64, { content: aux, name: f.name }];
                if (arrayBase64.length === sizeFiles && initialMount.current) {
                  let auxIn = null;
                  auxIn = arrayBase64.filter(fil => Object.keys(fil).length > 0);
                  getFileBase64(auxIn);
                  setNameFile(succesLabel);
                }
              });
            } else if (!validFile && initialMount.current) {
              // let howManyValids = statusFiles.filter(f => f.isValid);
              arrayBase64 = [...arrayBase64, {}];
            }

            if (statusFiles.length === sizeFiles) {
              setIsValidated(howManyValids.length);
              // return arrayBase64;
            }
          }
        });
      } else {
        notification.info({
          message: haveZip.length > 0 ?  t('upload.validationmax.zip') : t('upload.validationmax'),
          ...configInfo
        });
      }
    } else {
      if (e.target.files) {
        file = e.target.files[0];
        if (file) {
          let validFile = await validateFile(file);

          if (validFile && initialMount.current) {
            getBase64(file, result => {
              if (initialMount.current) {
                getFileBase64({ content: result, name: file.name });
                setNameFile(file.name);
              }
            });
          } else {
            if (initialMount.current) setNameFile('error');
            e.target.value = '';
            return false;
          }
        }
      }
    }
  };

  const validateFile = async file => {
    const fileName = file.name;
    const invalidExtension = '.rar';

    let regex = new RegExp('[^.]+$');

    let lower = file.name.toLowerCase();
    let fileExtension = lower.match(regex);

    const includesInvalidExtension = lower.includes(invalidExtension);
    const fileType = file.type;
    let validFiles = validTypesFile.join(',');
    let typeIsValid = false;
    if (fileType !== '') {
      typeIsValid = validFiles.includes(fileType);
      if (fileExtension && fileExtension.length > 0 && validImageFileExtensions) {
        typeIsValid = validImageFileExtensions.includes(fileExtension[0]);
      }
    }
    let sizeIsValid;
    massivePhotoFile
      ? (sizeIsValid = file.size / 150000000 / 150000000 < Number(size))
      : (sizeIsValid = file.size / 1024 / 1024 < Number(size));

    if (initialMount.current) setIsValidated(true);
    if (customMessage && (!sizeIsValid || !typeIsValid)) {
      if (customMessage === t('MSG_045') || customMessage === t('MSG_023')) {
        notification.info({
          message: customMessage,
          ...configInfo
        });
      } else {
        notification.error({
          message: customMessage,
          ...configError
        });
      }
    } else {
      if (!sizeIsValid && showErrorMessage) {
        notification.error({
          message: t('MSG_005'),
          ...configError
        });
      }
      if (!typeIsValid && showErrorMessage) {
        notification.error({
          message: `${t('upload.format')} ${formatsText}`,
          ...configError
        });
      }
    }

    if (additionalValidations) {
      const isValid = await getIsAllowed(
        typeIsValid,
        sizeIsValid,
        includesInvalidExtension,
        fileName
      );

      let auxObj = {
        name: fileName,
        size: sizeIsValid,
        type: typeIsValid,
        caseId: isValid,
        isValid: isValid ? true : false
      };
      let newA = [...statusFiles, auxObj];
      statusFiles = newA;

      return isValid;
    }
    let auxObj = {
      name: fileName,
      size: sizeIsValid,
      type: typeIsValid,
      isValid: typeIsValid && sizeIsValid && !includesInvalidExtension
    };
    let newA = [...statusFiles, auxObj];
    statusFiles = newA;

    return typeIsValid && sizeIsValid && !includesInvalidExtension;
  };

  const getIsAllowed = async (typeIsValid, sizeIsValid, includesInvalidExtension, fileName) => {
    if(!isProof){
      const response = await getCaseIdByFileName(fileName);
      if (typeof response !== 'undefined' && response.status === 200) {
        if (response.data.data !== '00000000-0000-0000-0000-000000000000') {
          const caseId = response.data.data;
          let isAllowedCase = allowedNames.includes(caseId);
          if (typeIsValid && sizeIsValid && !includesInvalidExtension && isAllowedCase) {
            return caseId;
          } else {
            return false;
          }
        }
      }
    }else{
      let fileNameWithOutExt = fileName.split(".");
      let isAllowedTax = allowedNames.includes(fileNameWithOutExt[0]);
      console.log(isAllowedTax);
      if (typeIsValid && sizeIsValid && !includesInvalidExtension && isAllowedTax) {
        return fileName;
      } else {
        return false;
      }
    }
  };

  const style = {
    opacity: 0,
    minHeight: '50px',
    width: '100%',
    marginLeft: '0px',
    padding: '0px',
    position: 'absolute',
    top: 0,
    left: 0
  };

  const getClassName = () => {
    switch (typeButton) {
      case 'primary':
        return 'ant-btn ant-btn-primary ant-btn-round';
      case 'default':
        return 'ant-btn ant-btn-primary ant-btn-round';
      default:
        return 'ant-btn ant-btn-default ant-btn-round';
    }
  };

  return (
    <div style={rootStyle}>
      <Button
        type={typeButton ? typeButton : ''}
        {...rest}
        className={`  ${getClassName()} ${nameFile !== '' && nameFile !== 'error' ? 'btn-strong' : ''
          }`}
      >
        {defaultName && instructionLabel}
        {!defaultName &&
          (nameFile !== '' && nameFile !== 'error' && isValidated ? (
            <em>{nameFile === succesLabel ? succesLabel : nameFile.substring(0, 19)}</em>
          ) : (
            instructionLabel
          ))}
        <input
          title={nameFile}
          ref={ref}
          key={fileInputKey}
          accept={validTypesFile}
          onChange={handleChange}
          style={style}
          type="file"
          multiple={allowsMultipleFiles}
        />
        <Icon
          style={iconStyle}
          type={
            nameFile !== '' && nameFile !== 'error' && isValidated && !defaultName
              ? 'check'
              : instructionIcon
          }
        />
      </Button>
    </div>
  );
});

UploadInput.propTypes = {
  additionalValidations: PropTypes.bool,
  allowsMultipleFiles: PropTypes.bool,
  customMessage: PropTypes.string,
  instructionLabel: PropTypes.string,
  showErrorMessage: PropTypes.bool,
  succesLabel: PropTypes.string,
  rootStyle: PropTypes.object,
  allowSize: PropTypes.number,
  iconStyle: PropTypes.object,
  validImageFileExtensions: PropTypes.any,
  defaultName: PropTypes.bool,
  reziseImage: PropTypes.bool,
  massivePhotoFile: PropTypes.bool
};

UploadInput.defaultProps = {
  massivePhotoFile: false,
  additionalValidations: false,
  allowsMultipleFiles: true,
  customMessage: '',
  instructionLabel: 'Adjuntar documento',
  instructionIcon: 'paper-clip',
  showErrorMessage: true,
  succesLabel: 'Adjuntado correctamente',
  rootStyle: null,
  allowSize: 10,
  iconStyle: { margin: '0px 10px' },
  validImageFileExtensions: false,
  defaultName: false,
  reziseImage: false
};

UploadInput.displayName = 'UploadInput';

export default UploadInput;
