import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SSelect from 'components/common/SSelect';
import { statesRequest } from 'redux/actions/catalogsActions';
import { useTranslation } from 'react-i18next';
import SCheckBoxGroup from 'components/common/SCheckBoxGroup';
import { validationsAllOption } from 'utils/validations';

function StatesSelect(props) {
  const {
    statesRequest: reqStates,
    form,
    label,
    isRequired,
    states,
    withAllOption,
    catalogType,
    setSelectedKeys,
    ...other
  } = props;

  const { t } = useTranslation();
  const [withAll, setWithAll] = useState(false);

  useEffect(() => {
    reqStates({ countryId: '240a6ef2-94e2-461d-b9ee-45e8a413ad93', withAllOption });
  }, [reqStates, withAllOption, catalogType]);

  useEffect(() => {
    validationsAllOption(
      catalogType,
      form,
      { inputKey: 'stateId' },
      setWithAll,
      withAll,
      states.data,
      setSelectedKeys
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogType, form, withAllOption, states.data, setSelectedKeys]);

  const catalogComponent =
    catalogType == 'checkbox' ? (
      <SCheckBoxGroup
        data-testid="group"
        form={form}
        inputKey="stateId"
        isLoading={states.isLoading}
        label={label}
        optionKey="id"
        options={states.data}
        placeholder={t('system.select')}
        valueKey="id"
        labelKey="name"
        {...other}
      />
    ) : (
      <SSelect
        inputKey="stateId"
        form={form}
        isRequired={isRequired}
        placeholder={t('system.select')}
        label={label}
        isLoading={states.isLoading}
        options={states.data}
        optionKey="id"
        valueKey="id"
        {...other}
      />
    );

  return catalogComponent;
}

function mapStateToProps(state) {
  return {
    states: state.catalogs.states
  };
}

StatesSelect.propTypes = {
  states: PropTypes.object,
  form: PropTypes.object.isRequired,
  statesRequest: PropTypes.func,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  withAllOption: PropTypes.bool
};

StatesSelect.defaultProps = {
  states: { data: [], isLoading: false },
  statesRequest: null,
  isRequired: false,
  label: 'Estado',
  withAllOption: false
};

export default connect(mapStateToProps, { statesRequest })(StatesSelect);
