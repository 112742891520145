import React from 'react';
import { Col } from 'antd';
import PropTypes from 'prop-types';
import '../../../assets/css/skeletonImage.css';

/**
 * Use SkeletonImage to rneder a dynamic number of cool images placeholders displayed when images are loading
 */
function SkeletonImage(props) {
  const { loading, numberOfSkeletons, withTitle, ...other } = props;

  const cardSkeleton = (
    <Col md={24} {...other}>
      <section className="card-skeleton">
        {withTitle && (
          <div className="card-detail-skeleton">
            <div
              className={`card-title-skeleton ${loading && 'loading-skeleton'}`}
              aria-hidden="true"
            />
          </div>
        )}

        <figure className={`card-image-skeleton ${loading && 'loading-skeleton'}`} />
      </section>
    </Col>
  );

  const renderNSkeletons = () => {
    let result = [];
    for (let index = 0; index < numberOfSkeletons; index++) {
      result.push(<div key={index}>{cardSkeleton}</div>);
    }
    return result;
  };

  return <> {renderNSkeletons()} </>;
}

SkeletonImage.propTypes = {
  loading: PropTypes.bool,
  numberOfSkeletons: PropTypes.number,
  withTitle: PropTypes.bool
};

SkeletonImage.defaultProps = {
  loading: false,
  numberOfSkeletons: 2,
  withTitle: true
};

SkeletonImage.displayName = 'SkeletonImage';

export default SkeletonImage;
