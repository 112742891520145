import React from 'react';
import { Col } from 'antd';
import PropTypes from 'prop-types';
import 'components/common/SkeletonTable/skeletonTable.css';

/**
 * Use SkeletonTable to rneder a dynamic number of cool images placeholders displayed when images are loading
 */

function SkeletonTable(props) {
  const { numberOfRows, numberOfHeads } = props;

  const HeadSkeleton = () => {
    return (
      <th className="loading">
        <div className="bar" />
      </th>
    );
  };

  const RowSkeleton = () => {
    return (
      <tr>
        <td className="loading">
          <div className="bar" />
        </td>
        <td className="loading">
          <div className="bar" />
        </td>
        <td className="loading">
          <div className="bar" />
        </td>
        <td className="loading">
          <div className="bar" />
        </td>
        <td className="loading">
          <div className="bar" />
        </td>
      </tr>
    );
  };

  const renderRowSkeletons = () => {
    let result = [];
    for (let index = 0; index < numberOfRows; index++) {
      result.push(<RowSkeleton key={index} />);
    }
    return result;
  };

  const renderHeadSkeletons = () => {
    let result = [];
    for (let index = 0; index < numberOfHeads; index++) {
      result.push(<HeadSkeleton key={index} />);
    }
    return result;
  };

  return (
    <Col md={24}>
      <div className="tableWrapper markdown">
        <table className="table-skeleton">
          <thead>
            <tr>{renderHeadSkeletons()}</tr>
          </thead>
          <tbody>{renderRowSkeletons()}</tbody>
        </table>
      </div>
    </Col>
  );
}

SkeletonTable.propTypes = {
  numberOfRows: PropTypes.number,
  numberOfHeads: PropTypes.number
};

SkeletonTable.defaultProps = {
  loading: false,
  numberOfRows: 4,
  numberOfHeads: 5
};

SkeletonTable.displayName = 'SkeletonTable';

export default SkeletonTable;
