import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

const { Title } = Typography;

/**
 * STitle is an encapsulation of Ant Desing Typography.
 */
function STitle(props) {
  const { titleText, level, children: Children, childrenProps, ...otherProps } = props;
  return (
    <Title {...otherProps} level={level}>
      {titleText} {Children ? <Children {...childrenProps}/> : null}
    </Title>
  );
}

STitle.propTypes = {
  titleText: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.node]).isRequired,
  level: PropTypes.number
};

STitle.defaultProps = {
  level: 3
};

export default STitle;
