import React, { useEffect, useState } from 'react'
import styles from "../styles";
import Image from '../ImageRender/ImageRender';
import { cleanNotifications, sendNotification } from 'helpers/helperNotifications';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

export const ZoomRenderer = props => {

    const {
        itemPosition,
        active,
        elementDimensions,
        itemDimensions,
        imageSrc,
        largeImageSrc,
        imageAlt,
        itemRef,
        renderOverlay,
        cursorStyle,
        cursorStyleActive,
        onImageLoad,
        onLargeImageLoad,
        onLoadRefresh
      } = props;

      const {t} = useTranslation();
      const [legalSize, setLegalSize] = useState(false);

      useEffect(() => {
        let isMounted = true;
        if (itemDimensions.width && elementDimensions.width && active && isMounted) {
            if(itemDimensions.width > elementDimensions.width) {
                setLegalSize(itemDimensions.width > elementDimensions.width);
            } else {
                cleanNotifications();
                sendNotification('info', t('zoom.unavailable'));
            }
        }
        return () => { isMounted = false };
      }, [itemDimensions.width, elementDimensions.width, active]);

      const isActive = legalSize && active;
      const finalCursorStyle = !legalSize
        ? "default"
        : active
        ? cursorStyleActive
        : cursorStyle;

  return (
    <div style={{ position: "relative", cursor: finalCursorStyle }}>
      <Image
        style={{
          display: "block",
          visibility: isActive ? "hidden" : "visible",
          width: "100%"
        }}
        src={imageSrc}
        alt={imageAlt}
        onImageLoad={onImageLoad}
        onLoadRefresh={onLoadRefresh}
      />
      <div
        style={styles.getZoomContainerStyle(
          elementDimensions.width,
          elementDimensions.height,
          true
        )}
      >
        <Image
          style={styles.getLargeImageStyle(
            itemPosition.x,
            itemPosition.y,
            isActive
          )}
          src={largeImageSrc || imageSrc}
          alt={imageAlt}
          ref={itemRef}
          onImageLoad={onLargeImageLoad}
          onLoadRefresh={onLoadRefresh}
        />
      </div>
      {renderOverlay ? renderOverlay(active) : null}
    </div>
  )
}
