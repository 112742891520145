import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SSelect from 'components/common/SSelect';
import { documentsGroupRequest } from 'redux/actions/catalogsActions';
import { useTranslation } from 'react-i18next';

function DocumentsGroup(props) {
  const {
    caseType,
    documentsGroupRequest: documentsGroupReq,
    form,
    initialValue,
    documentsGroup,
    onlyBuyerDocs,
    ...other
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    documentsGroupReq({ caseType: caseType });
  }, [documentsGroupReq, caseType]);

  //TODO: Make this work with a custom filter fucntion from props. Or remove the prop and make locally
  const filterData = (options, filter) => {
    let filteredOptions;
    if (options && filter) {
      filteredOptions = options.filter(element => element.id === 5 || element.id === 6);
    } else {
      filteredOptions = options;
    }
    return filteredOptions;
  };

  return (
    <SSelect
      jest-id="Documents-group"
      form={form}
      initialValue={initialValue}
      inputKey="documentGroup"
      isLoading={documentsGroup.isLoading}
      label={t('vehicledocument.category')}
      optionKey="id"
      options={filterData(documentsGroup.data, onlyBuyerDocs)}
      placeholder={t('system.select')}
      valueKey="id"
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      showSearch
      {...other}
    />
  );
}

function mapStateToProps(state) {
  return {
    documentsGroup: state.catalogs.documentsGroup
  };
}

DocumentsGroup.propTypes = {
  initialValue: PropTypes.any,
  documentsGroup: PropTypes.object,
  form: PropTypes.object.isRequired,
  engineTypeRequest: PropTypes.func,
  caseType: PropTypes.oneOf([1, 2]),
  onlyBuyerDocs: PropTypes.bool
};

DocumentsGroup.defaultProps = {
  initialValue: undefined,
  documentsGroup: { data: [], isLoading: false },
  engineTypeRequest: null,
  caseType: 1,
  filterBy: undefined,
  onlyBuyerDocs: false
};

export default connect(mapStateToProps, { documentsGroupRequest })(DocumentsGroup);
