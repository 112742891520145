import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Layout, Button, Icon } from 'antd';
import { NavLink } from 'react-router-dom';
import Bell from './Notification';
import mainLogo from '../../../assets/img/logov1.svg';
import TopBarMenu from 'components/common/TopBar/TopBarMenu';
import Message from './Message';

import { advertsStatusEnum, caseTypes, usersRoles } from 'models/enums';
import useWasRendered from 'hooks/useWasRendered';
import { requestComponents } from 'redux/actions/componentsActions';
import { useTranslation } from 'react-i18next';
import { messageService } from 'services/message';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { saveBuyerFilter } from 'redux/actions/advertActions';
import { paginationCurrentPage } from 'redux/actions/pagination';
import { advertOnlineDetailVisited, searchAdverts4BuyersSuccess } from 'redux/actions/buyersActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { objectHasProperty } from 'utils';


const { Header } = Layout;

function TopBar(props) {  
  const {
    externalRegister, 
    userRole, 
    temporary, 
    requestComponents, 
    headerComponents, 
    floatComponents,
    advertOnlineDetailVisited,
    searchAdverts4BuyersSuccess,
    saveBuyerFilter,
    paginationCurrentPage,
    buyerQuerySearch,
    temporaryRedux
  } = props;
  const { wasRendered, setWasRendered } = useWasRendered();
  const [advert, setAdvert] = useState(null);
  const [caseType, setCaseType] = useState(null);
  const [similarActive, setSimilarActive] = useState(false);
  const location = useLocation();
  const {t} = useTranslation();
  const history = useHistory();
  const [childButtons, setChildButtons] = useState([]);
  const AllowedBellRoles = [usersRoles.PERSONAL_BUYER, usersRoles.MORAL_BUYER, usersRoles.ADMIN_MASTER, usersRoles.ADMIN_INTERN];
  const searchBuyer = useSelector(state => state.buyers.filter);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const observer = messageService.getMessage().subscribe(message => {
      if(message && message.type === 'findSame' && message?.advertData){
        setCaseType(message.advertData.caseTypeId ?? message.advertData.caseType)
        setAdvert(message.advertData);
      }else if(message && message.type === 'caseTypeChange' && message?.caseType){
        setCaseType(message.caseType);
      }else if(message && message.type === 'resetAdvert'){
        setAdvert(null);
      }else if(message && message.type === 'handleSimilarOutside' && message.advert, message.querySearch){
        handleOnSimilar(message.advert, message.querySearch);
      }
    });    
    if (!wasRendered.current && !externalRegister) {
      try {
        getComponents();
      } catch (error) {
      }
      setWasRendered(true);
    }
    return () => {
      observer.unsubscribe();
      setAdvert(null);
      setSimilarActive(false);
    }
  }, [setWasRendered, wasRendered, externalRegister]);

  useEffect(() => {
    if(caseType == null && advert?.caseTypeId){
      setCaseType(advert.caseTypeId);
    }
    generateMenu();
  }, [ location, floatComponents, caseType, advert])
  

  const getComponents = () =>{
    if(buyerQuerySearch?.isFromSimilar && buyerQuerySearch?.isFilter)
      setSimilarActive(true);
    else
      setSimilarActive(false);
    requestComponents({ componentType: 'headermenu' });
    requestComponents({ componentType: 'floatMenu' });
  }

  const generateMenu = () =>{
    if(floatComponents){
      let newButtons = [];
      floatComponents.forEach(element => {
        const regexPath = new RegExp(element.path);
        //show only online vehicles
        if(regexPath.source === /^\/adverts\/list\/online\/[a-zA-Z0-9]+$/.source && regexPath.test(location?.pathname) && ( caseType == caseTypes.VEHICULOS && ( advert || similarActive ))){
          newButtons.push(element);
          console.log('online');
        }
        //show only advert detail vehicles
        else if(regexPath.source == /^\/adverts\/buyers\/detail\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/.source  && regexPath.test(location?.pathname) && caseType == caseTypes.VEHICULOS && advertsStatusEnum.ACTIVA == advert?.advertStatus ){
          newButtons.push(element);
          console.log('detail');
          console.log(caseType);
        }
        else if(regexPath.test(location?.pathname) && regexPath.source !== /^\/adverts\/list\/online\/[a-zA-Z0-9]+$/.source && regexPath.source !== /^\/adverts\/buyers\/detail\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/.source){
          newButtons.push(element);
          console.log('else');
        }
      });
      setChildButtons(newButtons);
    }
  }

  const handleOnSimilar = (advertParam = null, querySearchParam = null) => {
    let advertToSet = objectHasProperty(advertParam, 'advertId') ? advertParam : advert;
    let querySearchToSet = querySearchParam ?? buyerQuerySearch;
    
    setSimilarActive(!similarActive);
    let objToSet = {};
    let caseType = null;
    if(!similarActive){
      if(advertToSet && (advertToSet.caseTypeId != caseTypes.DIVERSOS || advertToSet.caseType != caseTypes.DIVERSOS)){
        caseType = advertToSet.caseType ?? advertToSet.caseTypeId;
        objToSet = {data : [], querySearch: {
          onlyActive : true,
          page: 1,
          caseType: caseType,
          isSearchAdvanced: true,
          model: Number(advertToSet.model)  - 1,
          modelEnd : Number(advertToSet.model) + 1,
          brand: advertToSet.brand,
          isFilter: true,
          isFromSimilar: true,
          lastSimilar : { isFilter: true, ...querySearchToSet}
        }};
      }
    }else{
        let buyerQueryCopy = {...querySearchToSet};
        delete buyerQueryCopy.isFromSimilar
        objToSet = {data : [], querySearch: buyerQueryCopy?.lastSimilar ? {...buyerQueryCopy.lastSimilar, lastSimilar: buyerQueryCopy?.lastSimilar} : buyerQueryCopy};
    }
    advertOnlineDetailVisited(true);
    searchAdverts4BuyersSuccess(objToSet);
    saveBuyerFilter(objToSet.querySearch);
    paginationCurrentPage({ currentPage: 1 });
    history.push('/adverts/list/online/vehicle');
    setAdvert(null);
  };

  useEffect(() => {
    
    if(buyerQuerySearch?.extraParam === undefined){
      setIsActive(false);
    }    
    else if(buyerQuerySearch?.extraParam !== null){
      setIsActive(true);
    }
    else{
      setIsActive(false);
    }
  }, [ location, buyerQuerySearch, caseType, advert])

  const handleNavClick = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <Header
        data-testid="top-bar-component"
        className="header"
        style={{
          position: 'fixed',
          zIndex: 155,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div>
          <div className="logo">
            <img
              rel="preload"
              as="image"
              loading="lazy"
              width="250px"
              data-testid="logo-top-bar"
              alt="logo"
              src={mainLogo}
            />
          </div>
          <div className='headerActions'>

            {headerComponents?.map((data, index) => {              
              return (
                <>
                  <NavLink className="ant-btn iconsHeader"
                    to={{
                      pathname: data.path,
                      state: {
                        data: {
                          Parameter: !isActive ? data.parameter : null
                        }
                      }
                    }}
                    activeStyle={isActive ? { backgroundColor: '#68368b', color: 'white' } : ''}
                    onClick={handleNavClick}
                    activeClassName={isActive ? 'active' : ''}
                  >
                    {data.text}

                    {data.icons.map((data, index) => {
                      return (
                        <Icon type={data.icon} theme={data.theme} style={{ fontSize: '16px' }} />
                      );
                    })}

                  </NavLink>
                </>
              );
            })}

            {
              childButtons?.map((data, index) => {
                  return (
                    <>
                      <Button icon={similarActive ? data.icon.type : ''} onClick={handleOnSimilar} className='iconsHeader' type={similarActive ? 'primary' : 'secondary'} >{t(data.text)}</Button>
                    </>
                  )
              })
            }
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <Message />
          {(AllowedBellRoles.includes(userRole) && !temporaryRedux) && <Bell />}
          {!externalRegister && <TopBarMenu />}
        </div>
      </Header>
    </>
  );
}

const mapStateToProps = state => {
  return {
    userRole: state?.user?.info?.userRole ?? {},
    temporary: state?.user?.info?.temporary ?? false,
    floatComponents : state.components.type.floatingMenu,
    headerComponents : state.components.type.headerMenu,
    buyerQuerySearch : state.buyers.searchResults.querySearch
  };
};

export default connect(mapStateToProps, {
  requestComponents,
  advertOnlineDetailVisited,
  searchAdverts4BuyersSuccess,
  saveBuyerFilter,
  paginationCurrentPage
})(TopBar);
