import React, { forwardRef, useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { setRuleRequired } from 'utils/validations';
import { chunkTitle } from 'helpers/helperForms';
import PropTypes from 'prop-types';
import { orderArrayObjects } from 'utils';
import {
  filterOption as helperFilterOption,
  getPopupContainer as helperGetPopupContainer
} from 'helpers/helperForms';
import { useTranslation } from 'react-i18next';
const { Option } = Select;

const SSelect = forwardRef((props, ref) => {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isOtherOptionSelected, setIsOtherOptionSelected] = useState(false);
  const {
    form,
    initialValue,
    inputKey,
    isLoading,
    isRequired,
    label,
    labelKey,
    optionKey,
    options,
    placeholder,
    valueKey,
    getPopupContainer,
    filterOption,
    withAllOption,
    isModeTagsUsed,
    onChange,
    ...other
  } = props;

  const { getFieldDecorator, getFieldValue } = form;
  const requiredRule = isRequired ? [setRuleRequired(label)] : [];
  const currentValue = getFieldValue(inputKey);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      currentValue &&
      typeof currentValue !== 'undefined' &&
      (typeof currentValue === 'object' || typeof currentValue === 'string') &&
      currentValue.includes('*****')
    ) {
      setIsOtherOptionSelected(false);
      setIsAllSelected(true);
    } else if (
      currentValue &&
      typeof currentValue !== 'undefined' &&
      (typeof currentValue === 'object' || typeof currentValue === 'string') &&
      !currentValue.includes('*****') &&
      currentValue.length > 0
    ) {
      setIsAllSelected(false);
      setIsOtherOptionSelected(true);
    } else {
      setIsOtherOptionSelected(false);
      setIsAllSelected(false);
    }
  }, [currentValue]);

  const handleOnChange = (value, option) => {
    let aux = options;
    if (withAllOption) {
      let disabled = options.filter(op => op[optionKey] !== '*****');

      disabled.map(opt => {
        aux = [...aux, { ...opt, diabled: true }];
      });
    }

    if (onChange) {
      return { onChange: onChange(value, option, aux) };
    }

    return {};
  };

  const createOptionsList = () => {
    if (typeof options !== 'undefined' && options?.length > 0) {
      //
      let auxOptions = options;

      auxOptions.sort(orderArrayObjects(labelKey, false));
      return auxOptions.map(option => {
        if (option[optionKey] === '*****') {
          return (
            <Option
              {...chunkTitle(option[labelKey], 25)}
              key={option[optionKey]}
              value={option[valueKey]}
              disabled={option?.disabled || false || (isOtherOptionSelected && isModeTagsUsed)}
            >
              {option[labelKey]}
            </Option>
          );
        } else {
          return (
            <Option
              {...chunkTitle(option[labelKey], 25)}
              key={option[optionKey]}
              value={option[valueKey]}
              disabled={option?.disabled || false || (isAllSelected && isModeTagsUsed)}
            >
              {option[labelKey]}
            </Option>
          );
        }
      });
    }
  };

  const loadingSelect = {
    hasFeedback: true,
    validateStatus: 'validating'
  };

  const loading = isLoading ? loadingSelect : null;
  const initial = initialValue === null ? null : { initialValue: initialValue };

  return (
    <Form.Item label={label} {...loading}>
      {getFieldDecorator(inputKey, {
        rules: [...requiredRule],
        ...initial
      })(
        <Select
          showSearch
          data-testid="idtest"
          id={inputKey}
          ref={ref}
          {...other}
          getPopupContainer={getPopupContainer}
          placeholder={t(placeholder)}
          filterOption={filterOption}
          onChange={handleOnChange}
        >
          {createOptionsList()}
        </Select>
      )}
    </Form.Item>
  );
});

SSelect.propTypes = {
  form: PropTypes.object.isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputKey: PropTypes.string,
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  optionKey: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  getPopupContainer: PropTypes.func,
  filterOption: PropTypes.func,
  withAllOption: PropTypes.bool,
  onChange: PropTypes.any
};

SSelect.defaultProps = {
  initialValue: null,
  inputKey: 'select',
  isLoading: false,
  isRequired: false,
  label: 'Label',
  labelKey: 'name',
  optionKey: 'id',
  options: [],
  placeholder: 'system.select',
  valueKey: 'id',
  getPopupContainer: helperGetPopupContainer,
  filterOption: helperFilterOption,
  withAllOption: false,
  onChange: false
};

SSelect.displayName = 'SSelect';

export default SSelect;
