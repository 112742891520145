import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SSelect from 'components/common/SSelect';
import { requestCountries } from 'redux/actions/catalogsActions';
import { useTranslation } from 'react-i18next'

function CountriesSelect(props) {
  const { requestCountries: askForCountries, form, isRequired, countries, ...other } = props;
  const { t } = useTranslation();

  useEffect(() => {
    askForCountries();
  }, [askForCountries]);

  return (
    <SSelect
      form={form}
      inputKey="countryId"
      isLoading={countries.isLoading}
      isRequired={isRequired}
      label={t("usersform.country")}
      optionKey="id"
      options={countries.data}
      placeholder={t("system.select")}
      valueKey="id"
      data-testid="country-select"
      {...other}
    />
  );
}

function mapStateToProps(state) {
  return {
    countries: state.catalogs.countries
  };
}

CountriesSelect.propTypes = {
  countries: PropTypes.object,
  form: PropTypes.object.isRequired,
  requestCountries: PropTypes.func,
  isRequired: PropTypes.bool
};

CountriesSelect.defaultProps = {
  countries: { data: [], isLoading: false },
  requestCountries: null,
  isRequired: true
};

export default connect(mapStateToProps, { requestCountries })(CountriesSelect);
