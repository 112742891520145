import React, { useState, useEffect, useRef } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon, Button } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Loader from 'components/common/Loader';
import Styles from './TopBarMenu/top-bar-menu.module.css';
import { Circle, CheckCircle, Bell } from 'utils/icons';
import {
  latestNotificationsRequest,
  removeNotificationsRequest,
  updateNotificationRequest,
  pagination
} from 'modules/Notifications/redux-saga/actions';
import {getGlobalFileRequest} from '../../../redux/actions/filesActions'
import { attachmentType, notificationType } from 'models/enums';

function Notification({
  latestNotificationsRequest: fetchNotifications,
  notifications,
  removeNotificationsRequest: removeNotifications,
  updateNotificationRequest: updateNotification,
  getGlobalFileRequest,
  isLoading,
  pagination: setPagination,
  number,
  lastNumber
}) {
  const [display, setDisplay] = useState(false);
  const [news, setNotifications] = useState([]);
  const [ring, setRing] = useState(false);
  const wrapper = useRef(null);
  const history = useHistory();

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  useEffect(() => {
    function handleOutside(event) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setDisplay(false);
      }
    }
    document.addEventListener('mousedown', handleOutside);
    return () => {
      document.addEventListener('mousedown', handleOutside);
    };
  }, [wrapper]);

  useEffect(() => {
    setNotifications(notifications);
    if (number > lastNumber) {
      setRing(true);
      setTimeout(() => {
        setRing(false);
      }, 2000);
    }
  }, [notifications, lastNumber, number]);

  const checkOne = i => {
    const shallow = [...news];
    shallow[i].checked = !shallow[i].checked;
    setNotifications(shallow);
  };

  const checkAll = () => {
    if (news.filter(n => n.checked !== true).length) {
      setNotifications(news.map(n => ({ ...n, checked: true })));
    } else {
      setNotifications(news.map(n => ({ ...n, checked: false })));
    }
  };

  const removeChecked = () => {
    removeNotifications(news.filter(n => n.checked === true));
  };
  const redirect = n => {
    setDisplay(false);
    if (!n.read) {
      updateNotification(n.notificationId);
    }

    if(notificationType.REPORT_READY === n.typeId) {
      getGlobalFileRequest({foreignId: n.foreignId, mainFolder: 'reports', docType: attachmentType.DOCUMENT});
    }else if (n.url !== '#') {
      history.push(`${n.url}`);
    }
  };

  return (
    <>
      <div
        ref={wrapper}
        style={{
          display: 'flex'
        }}
      >
        <button
          className={Styles['button-notification']}
          onClick={() => {
            fetchNotifications();
            setDisplay(!display);
          }}
        >
          <Bell className={ring && Styles['animation']} />
          <span className={Styles['notification-number']}>
            {isLoading ? (
              <div style={{ marginTop: '-3px' }}>
                <Loader text="" size={11} />
              </div>
            ) : (
              number
            )}
          </span>
        </button>

        <div
          className={Styles['notification']}
          style={{
            display: display ? 'block' : 'none'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              padding: '0 10px',
              borderBottom: 'solid #68368B 0.5px',
              lineHeight: '35px'
            }}
          >
            <h3 className={Styles['notification-title']}>Notificaciones</h3>
            <Row type="flex" align="middle">
              <Button
                className={Styles['button-checkall']}
                onClick={checkAll}
                size="small"
                type="link"
              >
                <Icon type="check" />
                <span>Marcar todo</span>
              </Button>
              <Button onClick={removeChecked} size="small" type="link">
                <Icon type="delete" />
              </Button>
            </Row>
          </div>
          <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
            {news.map((n, i) => (
              <div key={i} style={{ borderBottom: 'solid 0.5px #68368B' }}>
                <div
                  style={{
                    padding: 10,
                    background: n.read ? 'white' : 'rgb(255 228 209 / 28%)'
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <div style={{ color: '#68368B', marginRight: 6 }}>
                      {n.checked ? (
                        <CheckCircle onClick={() => checkOne(i)} />
                      ) : (
                        <Circle onClick={() => checkOne(i)} />
                      )}
                    </div>
                    <Row type="flex" style={{ width: '100%' }}>
                      <Col span={24}>
                        <button
                          style={{
                            width: '100%',
                            background: 'transparent',
                            border: 'transparent',
                            cursor: 'pointer'
                          }}
                          onClick={() => redirect(n)}
                        >
                          <Row type="flex" justify="start" style={{ textAlign: 'start' }}>
                            <span className={Styles['notification-message']}>{n.message}</span>
                          </Row>
                          <Row type="flex" justify="end" style={{ fontSize: 10 }}>
                            {moment(n.registered).format('DD/MM/YYYY HH:mm:ss')}
                          </Row>
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Row
            type="flex"
            justify="center"
            style={{ lineHeight: '35px', borderTop: 'solid 0.5px #68368B' }}
          >
            <Link
              to="/notifications"
              className={Styles['load-more']}
              style={{ color: '#707173', fontSize: 12 }}
              onClick={() => {
                setDisplay(!display);
                setPagination(1);
              }}
            >
              <Button className={Styles['button-footer']} size="small" type="link">
                Ver más notificaciones
              </Button>
            </Link>
          </Row>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    notifications: state?.notifications?.latestNotifications,
    isLoading: state?.notifications?.firstLoad,
    number: state?.notifications?.currentNumber,
    lastNumber: state?.notifications?.lastNumber
  };
};

export default connect(mapStateToProps, {
  latestNotificationsRequest,
  removeNotificationsRequest,
  updateNotificationRequest,
  pagination,
  getGlobalFileRequest
})(Notification);
