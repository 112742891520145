import * as actions from './actionTypes';

const notification = {
    firstOpen: true
};

export default (state = notification, action) => {
    switch (action.type) {
        case actions.FIRSTNOTIFICATION:
            return { ...state, firstOpen: true };
        case actions.CLOSENOTIFICATION:
            return { ...state, firstOpen: false };
        default:
            return state;
    }
};
