import * as actions from './actionTypes';

export function setFirstNotification() {
    return {
        type: actions.FIRSTNOTIFICATION,
    };
}


export function setCloseNotification() {
    return {
        type: actions.CLOSENOTIFICATION,
    };
}