import React, { useEffect } from 'react';
import { Menu, Modal, Icon, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { requestLogOut } from 'redux/actions/authActions';
import { profileInfoRequest } from 'redux/actions/userActions';
import { buttonsProps } from 'helpers/helperModals';
import { headerBase64PNG } from '../../../../utils/constants';
import Styles from './top-bar-menu.module.css';
import useWasRendered from 'hooks/useWasRendered';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AntIcon = <Icon type="loading" style={{ fontSize: 44, marginRight: '22px' }} spin />;

const { SubMenu } = Menu;
function TopBarMenu({ profileInfoRequest: getAvatar, avatar, requestLogOut: logOut }) {
  const { wasRendered, setWasRendered } = useWasRendered();
  const { data, isLoading: loading } = avatar;
  const { t } = useTranslation();
  useEffect(() => {
    if (!wasRendered.current && data === undefined) {
      getAvatar();
      setWasRendered(true);
    }
  }, [data, getAvatar, setWasRendered, wasRendered]);

  const showConfirm = () => {
    Modal.confirm({
      title: t("closession.header"),
      okText: t("system.acept"),
      cancelText: t("system.cancel"),
      okButtonProps: buttonsProps,
      cancelButtonProps: buttonsProps,
      onOk() {
        logOut();
      }
    });
  };

  return (
    <Menu
      theme="light"
      id="top-bar"
      data-testid="menu-top-bar"
      mode="horizontal"
      style={{
        height: '100%',
        float: 'right',
        borderBottom: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        backgroundColor: '#fafafa'
      }}
    >
      {loading === false ? (
        <SubMenu
          data-testid="user-menu"
          title={
            !data && !data?.fileContent ? (
              <div className={Styles['container-icon']}>
                <Icon
                  type="user"
                  style={{
                    fontSize: '48px !important',
                    color: '#fff',
                    margin: '0px',
                    padding: '1px'
                  }}
                />
              </div>
            ) : (
              <div className={Styles['container-avatar']}>
                <img src={`${headerBase64PNG}${data}`} alt="img" />
              </div>
            )
          }
          style={{ borderBottom: '0px' }}
        >
          <Menu.Item>
            <Link to="/account/myProfile">
              <span> {t("topbar.myprofile")} </span>
            </Link>
          </Menu.Item>
          <Menu.Item data-testid="btn-logout" onClick={showConfirm}>
            {t("topbar.closesession")}
          </Menu.Item>
        </SubMenu>
      ) : (
        <Spin indicator={AntIcon} size="small" />
      )}
    </Menu>
  );
}

function mapStateToProps(state) {
  return { avatar: state?.user?.avatar || false };
}

export default connect(mapStateToProps, { profileInfoRequest, requestLogOut })(TopBarMenu);
