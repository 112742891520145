import SSelect from 'components/common/SSelect'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { requestRegimes } from 'redux/actions/catalogsActions';

const RegimeSelect = (props) => {
    const {form, requestRegimes: askForRegimes, isRequired, regimes, currentProfile, previousForm, ...other} = props;
    const { t } = useTranslation();

    useEffect(() => {
        askForRegimes({currentProfile, language: i18n.language});
    }, [askForRegimes])

    useEffect(() => {
        if(other.initialValue) {
            props.onChange(other.initialValue);
        }
    }, [other.initialValue])

    return (
        <SSelect
            inputKey="regimeId"
            form={form}
            isRequired={true}
            placeholder={t("system.select")}
            label={t("usersform.regime")}
            isLoading={false}
            optionKey="id"
            valueKey="id"
            options={regimes.data}
            {...other}
        />
    )
}

function mapStateToProps(state) {
    return {
        regimes: state.catalogs.regimes
    };
}

RegimeSelect.propTypes = {
    regimes: PropTypes.object,
    form: PropTypes.object.isRequired,
    requestRegimes: PropTypes.func,
    isRequired: PropTypes.bool
};

RegimeSelect.defaultProps = {
    regimes: { data: [], isLoading: false },
    requestRegimes: null,
    isRequired: true
};

export default connect(mapStateToProps, { requestRegimes })(RegimeSelect);
