import React from 'react'
import PropTypes from "prop-types";
import ReactInputPosition, {
  TOUCH_ACTIVATION,
  MOUSE_ACTIVATION
} from "react-input-position";
import { ZoomRenderer } from './ZoomRenderer/ZoomRenderer';

const ZoomImage = props  => {
  const {
    imageSrc,
    largeImageSrc,
    imageAlt,
    cursorStyle,
    cursorStyleActive,
    renderOverlay,
    dragToMove,
    className,
    style,
    mouseActivation,
    touchActivation,
    interactionSettings,
    onImageLoad,
    onLargeImageLoad,
    onImageError,
    onLargeImageError,
    onZoomStart,
    onZoomEnd
  } = props;

  const finalActiveCursorStyle =
    cursorStyleActive || dragToMove ? "move" : "zoom-out";

    return (
      <ReactInputPosition
        style={style}
        className={className}
        touchActivationMethod={touchActivation}
        mouseActivationMethod={mouseActivation}
        trackItemPosition
        centerItemOnActivatePos={dragToMove}
        alignItemOnActivePos={!dragToMove}
        onActivate={onZoomStart}
        onDeactivate={onZoomEnd}
        itemPositionLimitBySize
        {...interactionSettings}
      >
        <ZoomRenderer
          imageSrc={imageSrc}
          largeImageSrc={largeImageSrc}
          imageAlt={imageAlt}
          renderOverlay={renderOverlay}
          cursorStyle={cursorStyle}
          cursorStyleActive={finalActiveCursorStyle}
          onImageLoad={onImageLoad}
          onLargeImageLoad={onLargeImageLoad}
          onImageError={onImageError}
          onLargeImageError={onLargeImageError}
        />
      </ReactInputPosition>
    );
}


export default ZoomImage;