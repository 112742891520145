import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SSelect from 'components/common/SSelect';
import { documentTypesRequest } from 'redux/actions/catalogsActions';
import { useTranslation } from 'react-i18next';

function DocumentsTypeSelect(props) {
  const {
    groupId,
    documentTypesRequest: documentTypesReq,
    form,
    initialValue,
    documentsTypes,
    disabled,
    ...other
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    if (groupId) documentTypesReq({ caseType: groupId });
  }, [documentTypesReq, groupId]);

  return (
    <SSelect
      jest-id="Documents-select"
      form={form}
      initialValue={initialValue}
      inputKey="documentTypes"
      isLoading={documentsTypes.isLoading}
      disabled={documentsTypes.isLoading || disabled}
      label={t('vehicledocument.documenttype')}
      optionKey="id"
      options={documentsTypes.data}
      valueKey="id"
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      showSearch
      {...other}
      placeholder={t('system.select')}
    />
  );
}

function mapStateToProps(state) {
  return {
    documentsTypes: state.catalogs.documentsTypes
  };
}

DocumentsTypeSelect.propTypes = {
  initialValue: PropTypes.any,
  documentsTypes: PropTypes.object,
  form: PropTypes.object.isRequired,
  engineTypeRequest: PropTypes.func,
  groupId: PropTypes.number
};

DocumentsTypeSelect.defaultProps = {
  initialValue: undefined,
  documentsTypes: { data: [], isLoading: false },
  engineTypeRequest: null,
  groupId: undefined
};

export default connect(mapStateToProps, { documentTypesRequest })(DocumentsTypeSelect);
