import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import SSelect from 'components/common/SSelect';
import SCheckBoxGroup from 'components/common/SCheckBoxGroup';
import SRadioButtonGroup from 'components/common/SRadioButtonGroup';
import { requestMarkets } from 'redux/actions/catalogsActions';
import { marketAllowedRequest } from 'redux/actions/caseActions';
import { servicesTypeENUM } from 'models/enums/index';
import { validationsAllOption } from 'utils/validations';
import { messageService } from 'services/message';

function MarketsCatalog(props) {
  const [withAll, setWithAll] = useState(false);
  const {
    requestMarkets: askForMarkets,
    serviceTypeId,
    catalogType,
    form,
    label,
    labelKey,
    marketAllowedByCaseType,
    markets,
    withAllOption,
    isModeTagsUsed,
    marketsAllowed,
    mode,
    setSelectedKeys,
    ...other
  } = props;
  const dispatch = useDispatch();
  const optionsMarkets = marketAllowedByCaseType ? marketsAllowed : markets;

  useEffect(() => {
    if (marketAllowedByCaseType) {
      let serviceType;
      switch (serviceTypeId) {
        case 1:
          serviceType = servicesTypeENUM.VEHICULOS;
          break;
        case 2:
          serviceType = servicesTypeENUM.DIVERSOS;
          break;

        default:
          serviceType = serviceTypeId;
          break;
      }

      if (serviceType) dispatch(marketAllowedRequest({ caseTypeId: serviceType, withAllOption }));
    } else {
      askForMarkets({withAllOption});
    }
  }, [askForMarkets, serviceTypeId, marketAllowedByCaseType, dispatch, withAllOption]);

  useEffect(() => {
    validationsAllOption(catalogType, form, props, setWithAll, withAll, optionsMarkets, setSelectedKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogType, form, props.inputKey, withAll, optionsMarkets, setSelectedKeys]);

  const commonProps = {
    labelKey: labelKey,
    label: label,
    options: optionsMarkets,
    withAllOption: withAllOption,
    isModeTagsUsed: isModeTagsUsed
  };

  const commonSelectProps = {
    showSearch: true,
    tokenSeparators: ',',
    maxTagTextLength: 10,
    mode,
    size: 'large',
    notFoundContent: 'No se encontro ningún mercado'
  };

  const renderCatalogType = () => {
    let catalogComponent;
    switch (catalogType) {
      case 'radio':
        catalogComponent = (
          <SRadioButtonGroup data-testid="group" form={form} {...commonProps} {...other} />
        );
        break;

      case 'checkbox':
        catalogComponent = (
          <SCheckBoxGroup data-testid="group" form={form} {...commonProps} {...other} />
        );
        break;

      case 'select':
        catalogComponent = (
          <SSelect data-testid="group" showSearch form={form} {...commonProps} {...other} />
        );
        break;

      default:
        catalogComponent = (
          <SSelect
            data-testid="group"
            showSearch
            form={form}
            {...commonSelectProps}
            {...commonProps}
            {...other}
          />
        );
        break;
    }
    return catalogComponent;
  };

  return renderCatalogType();
}

function mapStateToProps(state) {
  return {
    markets: state.catalogs.markets,
    marketsAllowed: state.case.marketsAllowed
  };
}

MarketsCatalog.propTypes = {
  serviceTypeId: PropTypes.oneOf([1, 2, 4, 5]),
  catalogType: PropTypes.string,
  form: PropTypes.object.isRequired,
  inputKey: PropTypes.string,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  marketAllowedByCaseType: PropTypes.bool,
  markets: PropTypes.array,
  requestMarkets: PropTypes.func,
  withAllOption: PropTypes.bool,
  isModeTagsUsed: PropTypes.bool
};
MarketsCatalog.defaultProps = {
  serviceTypeId: 4,
  catalogType: 'select',
  inputKey: 'insuranceCarrierId',
  label: 'Aseguradoras',
  labelKey: 'businessName',
  marketAllowedByCaseType: false,
  markets: [],
  requestMarkets: null,
  withAllOption: false,
  isModeTagsUsed: false,
  mode: 'multiple'
};
export default connect(mapStateToProps, { requestMarkets })(MarketsCatalog);
