import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import SButton from 'components/common/SButton';

/**
 * SearchButton is an special SButton to trigger starting a search and
 * to show the status of searching
 */

function SearchButton(props) {
  const { isSearching, onClick, isSearchingLabel, searchLabel } = props;
  const { t } = useTranslation();
  return (
    <SButton
      label={isSearching ? t('system.searching') : t('system.search')}
      type="primary"
      shape="round"
      onClick={onClick}
      loading={isSearching}
      htmlType="submit"
      disabled={isSearching}
    />
  );
}

SearchButton.propTypes = {
  isSearching: PropTypes.bool,
  isSearchingLabel: PropTypes.string,
  searchLabel: PropTypes.string,
  onClick: PropTypes.func
};

SearchButton.defaultProps = {
  isSearching: false,
  isSearchingLabel: 'Buscando...',
  searchLabel: 'Buscar',
  onClick: null
};

export default SearchButton;
