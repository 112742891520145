import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Layout } from 'antd';
import { Alert } from 'antd';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { setCloseNotification } from './actions';
import { usersRoles } from 'models/enums';


function Message({ userRole, user, openModal, temporary }) {

    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const dispatch = useDispatch();
    const now = new Date();
    const date2 = moment(user.validTo, "YYYY-MM-DD").toDate();
    const diffTime = Math.abs(date2 - now);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    var value = `${t('message.validity')}: ${diffDays} ${t('table.days')}`;
    const handleOk = () => {
        if (openModal) {
            setIsModalOpen(false);
            dispatch(setCloseNotification());
        } else {
            setIsModalOpen(false);
        }
    };

    useEffect(() => {
        if ((userRole === usersRoles.PERSONAL_BUYER && !temporary) ||
            (userRole === usersRoles.MORAL_BUYER && !temporary)) {
            if (openModal) {
                if (diffDays <= 45) {
                    setIsModalOpen(true);
                }
            }
        }
    });

    return (
        <>
            <Modal
                id="modal-id"
                title='Alerta!'
                style={{
                    top: 20,
                }}
                visible={isModalOpen}
                onCancel={handleOk}
                footer={[
                    <Button key="submit" type="primary" onClick={handleOk}>
                        OK
                    </Button>,
                    ,
                ]}
            >
                <p>{value}</p>
            </Modal>
            <div style={{ display: 'flex' }}>
                {((userRole === usersRoles.PERSONAL_BUYER && !temporary) ||
                    (userRole === usersRoles.MORAL_BUYER && !temporary)) ?
                    (diffDays <= 45) ?
                        <>
                            <Alert message={value} type="error" showIcon style={{ border: 'none', backgroundColor: 'transparent', fontSize: '100%', fontWeight: 'bold' }} />
                        </>
                        :
                        <Alert message={value} type="success" showIcon style={{ border: 'none', backgroundColor: 'transparent' }} />
                    : <></>
                }
            </div>
        </>
    );
}


const mapStateToProps = state => {
    return {
        userRole: state?.user?.info?.userRole ?? {},
        user: state?.user.info ?? {},
        openModal: state?.message?.firstOpen ?? false,
        temporary: state?.user?.info?.temporary ?? false
    };
};

export default connect(mapStateToProps)(Message);
