import React, { forwardRef, useEffect } from 'react';
import SInput from 'components/common/SInput';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { setInvalidFormat } from 'utils/validations';
import { useTranslation } from 'react-i18next'

const EmailCreator = forwardRef((props, ref) => {
  const { form, equalPass, newDate } = props;
  const { getFieldValue, setFieldsValue, setFields } = form;
  const { t } = useTranslation();

  useEffect(() => {
    if (equalPass === true) {
      const value = getFieldValue('password');
      setFields({
        password: {
          value: value,
          errors: [new Error(t('MSG_012'))]
        }
      });
    }
  }, [equalPass, setFields, getFieldValue, newDate]);

  const validationNewPassword = {
    validator: (rule, value, callback) => {
      console.warn('rule', rule);
      setInvalidFormat(
        'NEW_PASSWORD',
        'Nueva contraseña',
        value,
        callback,
        getFieldValue('password')
      );
    }
  };

  const validationPassConfirmation = {
    validator: (rule, value, callback) => {
      console.warn('rule', rule);
      setInvalidFormat(
        'EXACT_VALUE',
        'Confirmar contraseña',
        value,
        callback,
        getFieldValue('newPassword')
      );
    }
  };

  const validationPassValid = {
    validator: (rule, value, callback) => {
      console.warn('rule', rule);
      setInvalidFormat(
        'VALID_PASSWORD',
        'password',
        value,
        callback,
        getFieldValue('password'),
        equalPass
      );
    }
  };

  const handleOnNewPasswordChange = () => {
    setFieldsValue({ confirm: undefined });
  };

  return (
    <Form ref={ref}>
      <p>{t("updatepwdform.header")}</p>
      <ul>
        <li>{t("updatepwdform.opt1")}</li>
        <li>{t("updatepwdform.opt2")}</li>
        <li>{t("updatepwdform.opt3")}</li>
        <li>{t("updatepwdform.opt4")}</li>
      </ul>
      <SInput
        jest-id="currentPassword"
        inputType="password"
        validator={validationPassValid}
        isRequired
        inputKey="password"
        form={form}
        label={t("updatepwdform.currentpwd")}
        maxLength={16}
        equalPass={equalPass}
      />
      <SInput
        jest-id="newPassword"
        inputType="password"
        validator={validationNewPassword}
        isRequired
        inputKey="newPassword"
        form={form}
        label={t("updatepwdform.newpwd")}
        onChange={handleOnNewPasswordChange}
        maxLength={16}
      />
      <SInput
        jest-id="repeatPassword"
        inputType="password"
        validator={validationPassConfirmation}
        isRequired
        inputKey="confirm"
        form={form}
        label={t("updatepwdform.confirmnpwd")}
        maxLength={16}
      />
    </Form>
  );
});

EmailCreator.propTypes = {
  form: PropTypes.object.isRequired,
  equalPass: PropTypes.bool
};

EmailCreator.defaultProps = {
  equalPass: false
};

EmailCreator.displayName = 'EMailForm';

const EmailForm = Form.create({ name: 'email-in-modal' })(EmailCreator);

export default EmailForm;
