import React, { forwardRef, useState } from 'react';
import { Radio, Form } from 'antd';
import PropTypes from 'prop-types';
import { setRuleRequired } from '../../../utils/validations';
import { containerStyle } from './styles';
import './style.css';
import { useEffect } from 'react';

const SRadioButtonGroup = forwardRef((props, ref) => {
  const {
    label,
    inputKey,
    isRequired,
    labelKey,
    valueKey,
    disabled,
    options,
    initialValue,
    form,
    cbxHeight,
    ...other
  } = props;
  const { getFieldDecorator } = form;
  const [fixedStyle, setfixedStyle] = useState(containerStyle)

  useEffect(() => {
    if(cbxHeight){
      setfixedStyle({
        ...containerStyle,
        height: cbxHeight,
        overflowY: 'scroll',
        overflowX: 'hidden'
      })
    }
  }, [containerStyle]);

  const createOptionsGroup = () => {
    if (typeof options !== 'undefined' && options.length > 0) {
      return options.map(option => {
        return (
          <Radio style={{width: '100%'}} value={option[valueKey]}>{option[labelKey]}</Radio>
        );
      });
    }
  };

  const createDefaultValue = () => {
    if (typeof initialValue !== 'undefined' && initialValue !== null && initialValue.length === 1) {
      let intialValueArray = initialValue.map(option => option[valueKey]);

      return intialValueArray[0];
    }
  };

  const requiredRule = isRequired ? [setRuleRequired(label)] : [];

  return (
    <>
      <Form.Item label={label}>
        {getFieldDecorator(inputKey, {
          initialValue: createDefaultValue(),
          rules: [...requiredRule]
        })(
          <Radio.Group
            disabled={disabled}
            ref={ref}
            style={fixedStyle}
            {...other}
          >
            {createOptionsGroup()}
          </Radio.Group>
        )}
      </Form.Item>
    </>
  );
});

SRadioButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.object),
  inputKey: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  valueKey: PropTypes.string
};

SRadioButtonGroup.defaultProps = {
  disabled: false,
  isRequired: false,
  label: 'Label',
  options: [],
  labelKey: 'name',
  valueKey: 'id',
  inputKey: 'checkboxGroup',
  initialValue: {}
};

SRadioButtonGroup.displayName = 'SRadioButtonGroup';

export default SRadioButtonGroup;
