import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SSelect from 'components/common/SSelect';
import { requestTimeZones } from 'redux/actions/catalogsActions';
import { useTranslation } from 'react-i18next'

function TimeZonesSelect(props) {
  const { requestTimeZones: askForTimeZones, form, isRequired, timeZones, ...other } = props;
  const { t } = useTranslation();

  useEffect(() => {
    askForTimeZones();
  }, [askForTimeZones]);

  return (
    <SSelect
      form={form}
      inputKey="timeZoneId"
      isLoading={timeZones.isLoading}
      isRequired={isRequired}
      label={t("usersform.timezone")}
      optionKey="id"
      options={timeZones.data}
      placeholder={t("system.select")}
      valueKey="id"
      data-testid="timeZone-select"
      {...other}
    />
  );
}

function mapStateToProps(state) {
  if (state.catalogs.timeZones.data && state.catalogs.timeZones.data.length > 1) {
    state.catalogs.timeZones.data = state.catalogs.timeZones.data.map(element => {
      return ({
        ...element,
        name: element.description,
      })
    });
  }
  return {
    timeZones: state.catalogs.timeZones

  };
}

TimeZonesSelect.propTypes = {
  timeZones: PropTypes.object,
  form: PropTypes.object.isRequired,
  requestTimeZones: PropTypes.func,
  isRequired: PropTypes.bool
};

TimeZonesSelect.defaultProps = {
  timeZones: { data: [], isLoading: false },
  requestTimeZones: null,
  isRequired: true
};

export default connect(mapStateToProps, { requestTimeZones })(TimeZonesSelect);
