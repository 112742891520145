import React, { forwardRef } from 'react';
import { Switch, Form } from 'antd';
import PropTypes from 'prop-types';

const SSwitch = forwardRef((props, ref) => {
  const {
    disabled,
    form,
    inputKey,
    initialValue,
    isRequired,
    className,
    label,
    checkedChildren,
    unCheckedChildren,
    onChange,
    wrapperClassName,
    ...other
  } = props;

  const { getFieldDecorator } = form;
  const requiredRule = isRequired ? [{ required: true, message: 'Este campo es obligatorio' }] : [];

  return (
    <div className={wrapperClassName}>
      <Form.Item label={label ? label : ''}>
        {getFieldDecorator(inputKey, {
          valuePropName: 'checked',
          initialValue: initialValue,
          rules: [...requiredRule]
        })(
          <Switch
            data-testid="mySuitch"
            style={{ backgroundColor: '#f5222d' }}
            disabled={disabled}
            ref={ref}
            className={className}
            checkedChildren={checkedChildren}
            unCheckedChildren={unCheckedChildren}
            onChange={checked => {
              onChange(checked);
            }}
            {...other}
          />
        )}
      </Form.Item>
    </div>
  );
});

SSwitch.propTypes = {
  className: PropTypes.string,
  checkedChildren: PropTypes.string,
  unCheckedChildren: PropTypes.string,
  onChange: PropTypes.func,
  inputKey: PropTypes.string,
  isRequired: PropTypes.bool,
  other: PropTypes.object,
  initialValue: PropTypes.bool,
  wrapperClassName: PropTypes.string
};

SSwitch.defaultProps = {
  inputKey: 'sswitch',
  className: '',
  checkedChildren: 'Sí',
  unCheckedChildren: 'No',
  onChange: () => {},
  other: null,
  isRequired: true,
  initialValue: false,
  wrapperClassName: 'mysswitch'
};

SSwitch.displayName = 'InsurancesCheckBoxGroup';

export default SSwitch;
