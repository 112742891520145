import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import STitle from 'components/common/STitle';
import SInput from 'components/common/SInput';
import SButton from 'components/common/SButton';
import SearchButton from 'components/search/SearchButton';
import { sendNotification } from 'helpers/helperNotifications';
import { requestSearch } from 'redux/actions/insuredExternalActions';

function SearchInsuredForm(props) {
  const { showSearchInsured, form, history, toggleView, requestSearch: askForSearch } = props;
  const { push } = history;
  const { validateFields } = form;
  const [showContent, setShowContent] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const handleSubmitSearch = e => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        search({ ...values });
      }
    });
  };

  const onSearchSuccess = response => {
    sendNotification('success', 'Hemos encontrado el caso.');
    push({
      pathname: '/insured/external',
      state: { insuranceCarrier: response.data.data }
    });
  };

  const onSearchFail = () => {
    setIsSearching(false);
  };

  const search = async params => {
    setIsSearching(true);
    askForSearch({
      onSearchFail: onSearchFail,
      onSearchSuccess: onSearchSuccess,
      searchParams: params
    });
  };

  useEffect(() => {
    let timer = setTimeout(() => setShowContent(showSearchInsured), 800);
    return () => {
      clearTimeout(timer);
    };
  }, [showSearchInsured]);

  const { t } = useTranslation();

  return (
    <Form
      className="text-left"
      id="insured-external-form"
      labelCol={{ span: 10 }}
      noValidate
      onSubmit={handleSubmitSearch}
      wrapperCol={{ span: 14 }}
    >
      {showSearchInsured && (
        <>
          <STitle className="text-left" level={3} titleText={t('searchinsurer.searchtext')} />

          {showContent && (
            <Row type="flex" justify="center" align="middle">
              <Col sm={24} md={24} lg={24} xl={24}>
                <SInput
                  allowClear
                  form={form}
                  // initialValue="CIT2002000028"
                  inputKey="caseSerial"
                  isRequired
                  label={t('searchinsurer.case')}
                  maxLength={13}
                  minRequired={2}
                  placeholder="xxxx"
                />
              </Col>

              <Col sm={24} md={24} lg={24} xl={24}>
                <SInput
                  allowClear
                  form={form}
                  //initialValue="02644779"
                  inputKey="caseNumber"
                  isRequired
                  label={t('searchinsurer.claim')}
                  maxLength={30}
                  minRequired={2}
                  placeholder="xxxx"
                />
              </Col>

              <Col sm={24} md={24} lg={24} xl={24}>
                <SInput
                  allowClear
                  form={form}
                  //initialValue="3VWRV09MX6M042905"
                  inputKey="vehicleSerial"
                  isRequired
                  label={t('searchinsurer.vin')}
                  maxLength={17}
                  minRequired={2}
                  placeholder="xxxx"
                />
              </Col>
              <Col sm={24} md={24} lg={24} xl={24} className="text-right">
                <SButton
                  label={t('system.cancel')}
                  shape="round"
                  style={{ margin: '0px 10px' }}
                  onClick={toggleView}
                />
                <SearchButton
                  isSearching={isSearching}
                  onClick={e => handleSubmitSearch(e)}
                  jest-id="search"
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </Form>
  );
}

SearchInsuredForm.propTypes = {
  form: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  requestSearch: PropTypes.func,
  showSearchInsured: PropTypes.bool,
  toggleView: PropTypes.func
};

SearchInsuredForm.defaultProps = {
  requestSearch: null,
  showSearchInsured: false,
  toggleView: null
};

const WrappedSearchInsured = Form.create({ name: 'searchInsured' })(SearchInsuredForm);

export default withRouter(connect(null, { requestSearch })(WrappedSearchInsured));
