import React, { useState } from 'react';
import { Tooltip } from 'antd';
import SButton from '../SButton';
import PropTypes from 'prop-types';

function SToggleModalControl(props) {
  const {
    onOpen,
    onOk,
    onCancel,
    labelButton,
    iconButton,
    intialState,
    children,
    tooltipText,
    conditionalClose,
    setEqualPass,
    equalPass,
    ...other
  } = props;
  const [showModal, setShowModal] = useState(intialState);

  const localOnOk = () => {
    if (!conditionalClose) {
      if (onOk) onOk();
      setShowModal(false);
    } else {
      if (onOk) {
        onOk(setShowModal);
      }
    }
  };

  const localOnCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setShowModal(false);
    if (equalPass === true) {
      setEqualPass(false);
    }
  };

  const localOnOpen = () => {
    if (onOpen) {
      onOpen();
    }
    setShowModal(true);
  };

  return (
    <>
      {React.cloneElement(children, {
        onCancel: localOnCancel,
        onOk: localOnOk,
        show: showModal,
        visible: showModal,
        id: 'elementToggleModal'
      })}
      <Tooltip title={tooltipText}>
        <SButton
          style={{ marginBottom: '.5rem' }}
          label={labelButton}
          icon={iconButton}
          onClick={localOnOpen}
          {...other}
        />
      </Tooltip>
    </>
  );
}

SToggleModalControl.propTypes = {
  labelButton: PropTypes.string,
  iconButton: PropTypes.string,
  intialState: PropTypes.bool,
  children: PropTypes.node,
  onOpen: PropTypes.func,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  tooltipText: PropTypes.string,
  conditionalClose: PropTypes.bool,
  setEqualPass: PropTypes.func,
  equalPass: PropTypes.bool
};

SToggleModalControl.defaultProps = {
  labelButton: null,
  iconButton: 'camera',
  intialState: false,
  children: <div />,
  onOpen: null,
  onOk: null,
  onCancel: null,
  setEqualPass: null,
  equalPass: false,
  tooltipText: null,
  conditionalClose: false
};

export default SToggleModalControl;
