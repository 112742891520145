import React from 'react';
import { Button, Collapse } from 'antd';
import { companyName, companyLegalName, companyURL } from '../../../utils/constants';
import TerminosPDF from '../../../assets/pdfs/TerminosyCondiciones.pdf';

function TermsAndConditions() {
  const { Panel } = Collapse;

  const nameCompany = <strong>{`"${companyName}"`}</strong>;
  const nameCompanyLegal = <strong>{`${companyLegalName}`}</strong>;
  const buttonLink = (
    <Button
      href={companyURL}
      className={`link-${companyName.toLowerCase()}`}
      target="_blank"
      type="link"
    >
      {companyURL}
    </Button>
  );

  return (
    <div id="container-terms-and-conditions">
      <Button id="btn-download-terms" href={TerminosPDF} type="link" download>
        Descargar
      </Button>
      <p>
        {nameCompanyLegal} sociedad de nacionalidad mexicana, pone a su disposición los siguientes
        “Términos y Condiciones” para el acceso al sitio web
        {buttonLink} los cuales regulan el uso, contenidos y servicios puestos a disposición de los
        usuarios. A través del sitio web se proporciona a los usuarios la plataforma en la que,
        previo registro, podrán realizar la comercialización de vehículos siniestrados y vehículos
        usados, así como, de vehículos de flotilla.
      </p>

      <Collapse defaultActiveKey={['1']}>
        <Panel header="Términos Generales" key="1">
          <p>
            Los presentes Términos y condiciones regulan la utilización del sitio
            {buttonLink}, incluyendo los contenidos y los servicios puestos a disposición de los
            usuarios. Al acceder y utilizar este servicio, usted acepta obligarse a cumplir estos
            términos y condiciones.
          </p>
        </Panel>
        <Panel header="Condiciones de uso" key="2">
          <ul>
            <li>
              Por medio del sitio web {buttonLink} {nameCompany} proporciona las plataformas en las
              que los usuarios anunciarán u ofertarán algún vehículo productos, bienes puestos a la
              venta.
            </li>
            <li>
              {nameCompany} no pretende convertirse en una parte contratante en los acuerdos o
              contratos de compra de vehículos a través de esta plataforma, sino que simplemente
              proporciona las plataformas como un proveedor de servicios.
            </li>
            <li>
              El usuario reconoce el hecho de que lo publicado: vehículos, productos, bienes puestos
              a la venta en las plataformas no son propiedad de {nameCompany}.
            </li>
            <li>
              {nameCompany} no se convertirá en un socio en los contratos de compra de vehículos,
              productos o bienes puestos a la venta en las plataformas, además, {nameCompany}no
              declarará bajo su nombre la compra o venta de vehículos, productos o bienes publicados
              en las plataformas.
            </li>
            <li>
              Como {nameCompany} no es en ningún momento el propietario de los vehículos o artículos
              publicados en las plataformas, se deslinda de toda responsabilidad por el estado y
              condición de los vehículos o bienes.
            </li>
            <li>
              {nameCompany} no somete estos vehículos o bienes a una técnica específica o a
              inspección visual. La descripción de los vehículos o bienes, así como la condición de
              los mismos se basa enteramente en la información proporcionada por el vendedor
            </li>
            <li>
              La utilización del sitio web {buttonLink} está sujeta al pago de las tasas de
              utilización establecidas en una lista de precios de acuerdo a las políticas de{' '}
              {nameCompany}.
            </li>

            <li>
              Celebración de los contratos de compra:
              <ol type="a">
                <li>
                  El vendedor se encargará de proporcionar o publicar la información de los
                  vehículos, piezas, accesorios u otros elementos de manera verídica.
                </li>
                <li>
                  El vendedor deberá determinar la fecha límite o duración de la oferta de vehículos
                  que se publicarán en el sitio web
                  {buttonLink}
                </li>
                <li>
                  El licitador estará obligado por la oferta presentada a:
                  <ol type="i">
                    <li>Durante tres semanas en la Plataforma de Salvamentos.</li>
                    <li>
                      Durante tres semanas en la Plataforma de Flotillas (días laborables de lunes a
                      viernes, excepto días festivos nacionales), después de finalizar la subasta o
                      fecha límite de licitación para el vehículo en cuestión, a no ser que se haya
                      acordado un periodo mayor de tiempo en forma particular como una excepción.
                    </li>
                  </ol>
                </li>
                <li>
                  El comprador puede presentar una oferta para cualquier vehículo que le interese.
                  Al enviar una puja, el comprador ha realizado una oferta de compra vinculante para
                  el propietario desconocido o distribuidor autorizado para vender el vehículo.
                </li>
                <li>
                  Una vez transcurrido el plazo de la oferta o finalizado la subasta el vendedor
                  puede llamar a las oferta más alta de acuerdo a las unidades publicadas. El
                  vendedor simplemente toma nota de las mismas.
                </li>
                <li>
                  El contrato de venta de autos usados no concluye hasta que la oferta se ha
                  aceptado explícitamente, tras una revisión de cada oferta por el propietario o
                  vendedor, quien es el último para autorizar la venta del vehículo. Ni los
                  proveedores o el propietario tienen la obligación de aceptar la oferta de compra
                  que se ha publicado, ya que esta debe cubrir las expectativas o monto previamente
                  establecido por los mismos.
                </li>
              </ol>
            </li>
            <li>
              Obligaciones del vendedor
              <ol type="a">
                <li>
                  Proporcionar oportunamente la información completa y correcta de los vehículos u
                  otros bienes para la venta; todos estos datos se enviarán a {nameCompany} antes de
                  que su publicación. Los datos facilitados también deberán incluir todas las
                  características de los bienes, así como los defectos existentes (técnicas y
                  diseño). {nameCompany} no se responsabiliza de los datos facilitados por el
                  oferente o proveedor.
                </li>
                <li>
                  Facilitar a {nameCompany} al menos 4 Fotografías (por lo menos 2 del interior),
                  acompañadas de una descripción detallada del actual estado del vehículo y todos
                  los detalles del vehículo de referencia (por ejemplo, fecha de primera
                  matriculación, tenencias, baja de placas, reporte de robo, etc), que se incluirá
                  en un formato digital (por ejemplo, Excel o un archivo de Word).
                </li>
                <li>
                  Asegurar que tanto {nameCompany} o el comprador del vehículo no estén violando los
                  derechos de un tercero.
                </li>
                <li>
                  Garantizar que está autorizado para publicar los vehículos en el sitio web{' '}
                  {buttonLink} lo que también incluye la autorización para utilizar y publicar las
                  fotografías.
                </li>
                <li>
                  Indemnizar plenamente a {nameCompany} frente a reclamaciones en relación con la
                  oferta de un vehículo en particular, debido a una inadecuada descripción o la no
                  existencia de la autorización mencionada en el punto anterior, o por cualquier
                  otra violación de la garantía o derechos realizada por el vendedor en relación con
                  la oferta o venta de un vehículo.
                </li>
              </ol>
            </li>
            <li>
              Los vehículos publicados en el sitio web {buttonLink} son exclusivamente autos usados
              o seminuevos, los cuales se ponen a la venta a compradores dedicados al sector
              automotriz. A menos que el correspondiente proveedor o vendedor no disponga otra cosa
              para los vehículos particulares, todos los vehículos puestos a la venta en se venden
              sin ninguna garantía.
            </li>
          </ul>
        </Panel>
        <Panel header="Obligaciones del Usuario" key="3">
          <p>
            El usuario deberá respetar en todo momento estos términos y condiciones, por lo que
            estará en todo momento obligado a usar el sitio de forma diligente y asumiendo cualquier
            responsabilidad que pudiera derivarse de su incumplimiento.
          </p>
          <p>
            Queda terminantemente prohibido transferir el número de usuario y contraseña, así como
            proporcionar información de una cuenta a un tercero sin previa autorización por escrito
            de {nameCompany}
            cualquier infracción resultará en la suspensión inmediata del usuario.
            {nameCompany} también se reserva el derecho a reclamar daños y perjuicios en tales
            casos. Cada usuario es responsable de velar por la no divulgación de su información de
            acceso.
          </p>
          <p>
            El uso del sitio {buttonLink} depende del adecuado registro de cada usuario, de manera
            que se tendrá que indicar toda la información requerida en el formulario de inscripción
            con veracidad. Además, se aplicarán las siguientes restricciones:
          </p>
          <ol>
            <li>
              El usuario estará obligado a informar inmediatamente a {nameCompany} sobre cualquier
              cambio en la información solicitada, previa inscripción. Además, {nameCompany} se
              reservará el derecho a revisar la información solicitada en cualquier momento, y a
              rechazar solicitudes de registro.
            </li>
            <li>
              {' '}
              {nameCompany} podrá suspender los registros de usuarios en caso de que haya algún
              indicio de que el usuario incumple o viola los presentes Términos y Condiciones, los
              derechos de un tercero o cualquier otro interés legítimo de {nameCompany} u otros
              usuarios.
            </li>
          </ol>
        </Panel>
        <Panel header="Propiedad Industrial" key="4">
          <p>
            El sitio {buttonLink} y su contenido original, las características y funcionalidad serán
            en todo momento propiedad de {nameCompany}. {nameCompany} no otorga derechos de autor u
            otros derechos de propiedad; esto incluye, en particular, todos los presentes y futuras
            ofertas, el software y la documentación correspondiente y las instrucciones.
          </p>
          <p>
            El material incluido en el sitio {buttonLink} se encuentra protegido por las
            disposiciones de protección de los derechos de autor y de propiedad industrial, por lo
            que los derechos de propiedad, explotación,comercialización y desarrollo, en los cuales
            se incluyen enunciativa más no limitativamente imágenes, fotografías, figuras animadas,
            video, audio, música y texto que en general se denominarán el “Contenido del Sitio”, así
            como los elementos de organización, diseño, disposición, diseño del Sitio y cada página
            individual del Sitio, todos serán en todo momento propiedad de {nameCompanyLegal}{' '}
          </p>
          <p>
            En el caso de que {nameCompany} haya infringido inadvertidamente en algunas fotos,
            derechos reservados o derechos de la propiedad industrial de un tercero, por favor
            póngase en contacto con nosotros. Se verificará y eliminará el contenido afectado, en
            caso de proceder.{' '}
          </p>
        </Panel>
        <Panel header="Protección de Datos" key="5">
          <p>
            {nameCompany} custodiará la información o datos personales de sus usuarios, a través de
            las medidas de seguridad, legalmente exigibles, de índole técnica y organizativa que
            garanticen la seguridad de los mismos, evitando su alteración, pérdida, tratamiento o
            acceso no autorizado.
          </p>
          <p>
            El procedimiento utilizado en el sitio {buttonLink} así como la administración de datos
            se realizarán de conformidad con el Aviso de Privacidad y la normativa de protección de
            datos. Los usuarios manifiestan conocen y están de acuerdo en que los datos ingresados
            por ellos podrán ser difundidos, utilizados, transferidos y almacenados por{' '}
            {nameCompany} con fines comerciales, siempre que la manipulación se realice dentro del
            ámbito de aplicación de administración de los datos generales y se maneje correcta y
            profesionalmente. {nameCompany}, tratará todos los datos con el mayor grado de
            confidencialidad objetiva posible.
          </p>
        </Panel>
        <Panel header="Limitación de responsabilidad" key="6">
          <p>
            {nameCompany} garantiza que el sitio {buttonLink} tendrá un servicio ininterrumpido;
            dicha garantía no aplicará para los resultados que se puedan obtener, ni de la exactitud
            o fiabilidad de cualquier información, servicio o materiales proporcionados a través del
            mismo.
          </p>
          <p>
            Esta exención de responsabilidad se aplicará a cualquier daño que pudiese causar por
            cualquier fallo de rendimiento, error, omisión, inexactitud, interrupción, defecto,
            retraso en operación o transmisión, virus de computador, falla de línea de comunicación,
            robo, destrucción o acceso no autorizado, alteración o uso del sitio
            {buttonLink}
          </p>
          <p>
            {nameCompany} no será en ningún momento propietario de los vehículos o artículos
            publicados en el sitio web
            {buttonLink} y se deslinda de toda responsabilidad por el estado y condición de los
            mismos. La descripción de los vehículos o bienes, así como la condición de los mismos se
            basa enteramente en la información proporcionada por el usuario.
          </p>
          <p>
            {nameCompany} no será responsable por conductas ofensivas o ilegales de otros usuarios o
            terceros, por lo que cualquier daño o perjuicio que le sea ocasionado por lo anterior,
            competerá exclusivamente a usted. En ningún caso, {nameCompany}, o cualquier persona o
            entidad implicada en la creación, producción o distribución del sitio o del contenido
            incluido en el mismo, será responsable por agravio (incluyendo por su propia
            negligencia) por cualquier daño, incluyendo, sin limitación, daños directos, indirectos,
            incidentales, especiales y punitivos, a consecuencia de o similares, incluidos, sin
            limitación, pérdida de beneficios o ingresos, uso o incapacidad de usar el sitio. Usted
            reconoce entonces que las disposiciones de esta sección se aplicarán a todo el uso y
            contenido del sitio.
          </p>
        </Panel>
        <Panel header="Contenido de terceros" key="7">
          <p>
            El sitio {buttonLink} podrá contener enlaces a sitios web de terceros, con los cuales
            {nameCompany} tiene un acuerdo, pero no tiene control, ni asume la responsabilidad por
            la información o resultados arrojados por dichos enlaces. La inclusión de tal enlace no
            implica necesariamente la aprobación de tales sitios por {nameCompany}. De acuerdo a lo
            anterior, si el usuario publica contenido o envía material y, a menos que se indique lo
            contrario, se entenderá como otorgado a {nameCompany} y sus afiliados, un derecho no
            exclusivo, libre de regalías, perpetuo e irrevocable para usar, reproducir, modificar,
            adaptar, publicar, traducir, distribuir y mostrar dicho contenido en cualquier medio.
          </p>

          <p>
            El usuario declara y garantiza que posee o controla todos los derechos sobre el
            contenido que publica, que el contenido es preciso, que el uso del contenido que se
            suministra no causará daño a ninguna persona o entidad, y que indemnizará a{' '}
            {nameCompany} o a sus afiliados por todas las reclamaciones resultantes del contenido
            que usted proporcione.
          </p>
          <p>
            {nameCompany} tiene el derecho, pero no la obligación de monitorear y editar o eliminar
            cualquier actividad o contenido. {nameCompany} no asume ninguna responsabilidad por
            cualquier contenido publicado por usted o cualquier tercero
          </p>
        </Panel>
        <Panel header="Incumplimiento" key="8">
          Los usos ilegales y/o no autorizados del sitio, incluyendo, sin limitación, cualquier
          actividad o uso que sean fraudulentos o de cualquier otra forma objetables, ilegales, o
          que violen estos Términos y Condiciones, los derechos de {nameCompany} o terceros
          autorizados, serán investigados, por lo que {nameCompany} podrá emprender cualquier acción
          legal que considere necesaria, incluyendo sin limitación, los procedimientos civiles y
          penales aplicables. De acuerdo a lo anterior, el usuario se obliga a indemnizar y a sacar
          en paz y a salvo a {nameCompany}, a los directores, funcionarios, empleados, y sus
          sociedades filiales, de cualquier demanda, responsabilidad, costos y gastos, de cualquier
          naturaleza, incluyendo honorarios de abogados en que incurriera, como resultado de su
          incumplimiento a los presentes Términos y Condiciones.
          {nameCompany} se reservará el derecho de restringir el acceso al sitio.
        </Panel>
        <Panel header="Modificación de los términos" key="9">
          <p>
            {nameCompany} tendrá el derecho en cualquier momento de cambiar o modificar los
            presentes Términos y Condiciones, o cualquier parte del mismo, o para imponer nuevas
            condiciones, incluyendo, sin limitación, tarifas y cargos por el uso. Dichos cambios,
            modificaciones, adiciones o supresiones entrarán en vigor inmediatamente después de su
            notificación, los cuales podrán ser dados por cualquier medio incluyendo, sin
            limitación, la publicación en el Sitio. Cualquier uso del Sitio por usted después de
            dicha notificación se considerará como aceptación de dichos cambios, modificaciones,
            adiciones o supresiones.
          </p>
        </Panel>
        <Panel header="Resolución de Controversias" key="10">
          <p>
            Los usuarios del sitio {nameCompany} estarán sujetos a la legislación mexicana y a la
            jurisdicción de los tribunales de la Ciudad de México, renunciando a cualquier otro
            fuero que en razón de sus domicilios presentes o futuros pudieran corresponderles o por
            cualquier otra circunstancia.
          </p>
        </Panel>
        <Panel header="Cláusula de Separabilidad" key="11">
          <p>
            En el caso de que alguna de las disposiciones particulares contenidas en estos Términos
            y Condiciones se considere inválida, inejecutable o incompleta, esto no tendrá ninguna
            incidencia sobre la validez de las disposiciones restantes.
          </p>
        </Panel>
      </Collapse>
    </div>
  );
}

export default TermsAndConditions;
