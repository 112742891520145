import React from 'react';
import { Col } from 'antd';
import PropTypes from 'prop-types';
import SInputPhone from '../SInputPhone';
import { cleanPhoneNumber } from 'utils';
import { layoutInputs } from 'helpers/helperForms';
import STitle from '../STitle';
import { useTranslation } from 'react-i18next'

function TelephonesContact(props) {
  const { form, fieldsRequired, previousForm, readOnly, withTitle, title } = props;
  const { t } = useTranslation();

  let initialCellPhoneNumber =
    previousForm && previousForm.onlyCellPhoneNumber
      ? cleanPhoneNumber(previousForm.onlyCellPhoneNumber)
      : '';
  initialCellPhoneNumber =
    previousForm && previousForm.cellPhoneNumber
      ? previousForm.cellPhoneNumber
      : initialCellPhoneNumber;

  let initialPhoneNumber = previousForm && previousForm.phoneNumber ? previousForm.phoneNumber : '';
  initialPhoneNumber =
    previousForm && previousForm.onlyPhoneNumber
      ? previousForm.onlyPhoneNumber
      : initialPhoneNumber;

  return (
    <React.Fragment>
      {withTitle && (
        <Col span={24}>
          <STitle className="wizard-title" level={4} titleText={title} />
        </Col>
      )}

      <Col {...layoutInputs}>
        <SInputPhone
          form={form}
          readOnly={readOnly}
          isRequired={fieldsRequired.includes('phoneNumber')}
          label={t("usersform.phonenumber")}
          inputKey="phoneNumber"
          initialValue={cleanPhoneNumber(initialPhoneNumber)}
          disabled={readOnly}
          className="border-radius-right"
          autoComplete="off"
        />
      </Col>

      <Col {...layoutInputs}>
        <SInputPhone
          form={form}
          readOnly={readOnly}
          isRequired={fieldsRequired.includes('cellPhoneNumber')}
          label={t("usersform.cellnumber")}
          inputKey="cellPhoneNumber"
          initialValue={cleanPhoneNumber(initialCellPhoneNumber)}
          disabled={readOnly}
          className="border-radius-right"
          autoComplete="off"
        />
      </Col>

      <Col {...layoutInputs}>
        <SInputPhone
          form={form}
          readOnly={readOnly}
          label={t("usersform.fax")}
          inputKey="faxNumber"
          initialValue={cleanPhoneNumber(previousForm?.faxNumber ?? '')}
          disabled={readOnly}
          autoComplete="off"
        />
      </Col>
    </React.Fragment>
  );
}

TelephonesContact.propTypes = {
  fieldsRequired: PropTypes.array,
  isRequired: PropTypes.bool,
  form: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  withTitle: PropTypes.bool,
  title: PropTypes.string,
  previousForm: PropTypes.any
};

TelephonesContact.defaultProps = {
  fieldsRequired: [],
  isRequired: false,
  readOnly: false,
  withTitle: false,
  title: '',
  previousForm: undefined
};

TelephonesContact.displayName = 'TelephonesContact';

export default TelephonesContact;
