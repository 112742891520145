import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SSelect from 'components/common/SSelect';
import { requestLanguages } from 'redux/actions/catalogsActions';
import { useTranslation } from 'react-i18next'
function LanguagesSelect(props) {
  const { requestLanguages: askForLanguages, form, isRequired, languages, ...other } = props;
  const { t } = useTranslation();

  useEffect(() => {
    askForLanguages();
  }, [askForLanguages]);

  return (
    <SSelect
      form={form}
      inputKey="languageId"
      isLoading={languages.isLoading}
      isRequired={isRequired}
      label={t("usersform.language")}
      optionKey="id"
      options={languages.data}
      placeholder={t("system.select")}
      valueKey="id"
      data-testid="language-select"
      {...other}
    />
  );
}

function mapStateToProps(state) {
  return {
    languages: state.catalogs.languages
  };
}

LanguagesSelect.propTypes = {
  languages: PropTypes.object,
  form: PropTypes.object.isRequired,
  requestLanguages: PropTypes.func,
  isRequired: PropTypes.bool
};

LanguagesSelect.defaultProps = {
  languages: { data: [], isLoading: false },
  requestLanguages: null,
  isRequired: true
};

export default connect(mapStateToProps, { requestLanguages })(LanguagesSelect);
